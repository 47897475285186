// config.js
const config = {
  api_key: "386e7f6e63msh5fb83a763de640ep1ed79bjsn6b26330e731c", // Replace this with your API base URL
  baseurl_api: "https://web.absenonline.net/api/", // Replace this with your API base URL
  // baseurl_api: 'https://absenc2.site:447', // Replace this with your API base URL
  // baseurl_api: 'http://202.147.196.62:88', // Replace this with your API base URL
  // baseurl_api: 'https://0f1c-103-47-132-45.ngrok-free.app', // Replace this with your API base URL

  // baseurl_api: 'https://d533-103-47-132-45.ngrok-free.app', // Replace this with your API base URL
  // baseurl_api: 'https://absencressida.site', // Replace this with your API base URLhttps://d533-103-47-132-45.ngrok-free.app
};

export default config;
