import * as faceapi from "face-api.js";
import React, { useState } from "react";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import config from "../../config";
const MySwal = withReactContent(Swal);

function FaceRecognition() {
  const [modelsLoaded, setModelsLoaded] = React.useState(false);
  const [captureVideo, setCaptureVideo] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [dataLocation, setDataLocation] = useState([]);
  const [faceList, setFaceList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const videoRef = React.useRef();
  const videoHeight = 480;
  const videoWidth = 375;
  const canvasRef = React.useRef();
  let face_api_token = null;
  const [userName, setUserName] = useState("Sedang menarik data ...");
  const selectedShift = JSON.parse(localStorage.getItem("selectedShift"));
  // const API_URL = "https://103.180.164.252:447/api/";
  // const API_URL = "https://web.absenonline.net/arei-solo/api/";
  const API_URL = config.baseurl_api;
  const [dataSetup, setDataSetup] = React.useState(null);

  // const API_URL = "https://36.94.139.66:447/api/";
  // const API_URL = "http://127.0.0.1:8000/api/";

  const { state } = useLocation();
  const [isWebcamOn, setIsWebcamOn] = useState(true);
  let counter = 0;
  let myInterval;

  const loadSetup = async () => {
    setIsLoading(true);
    const response = await axios.get(API_URL + "getSetup");
    setDataSetup(response.data.data);
    setIsLoading(false);
  };
  React.useEffect(() => {
    // console.log(state);
    // const loadModels = async () => {
    //   const MODEL_URL = process.env.PUBLIC_URL + "/models";
    //   Promise.all([
    //     faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
    //     faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
    //     faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
    //     faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
    //     faceapi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL),
    //   ]).then(setModelsLoaded(true), loadFaceData());
    //   // cache loaded models to local storage
    // };
    const loadModels = async () => {
      const apikey = config.api_key;

      console.log(apikey);
      try {
        const response = await axios.get(
          "https://face-recognition-api1.p.rapidapi.com/faceApi",
          {
            headers: {
              "Content-Type": "application/json",
              "x-rapidapi-key": apikey,
              "x-rapidapi-host": "face-recognition-api1.p.rapidapi.com",
            },
          }
        );
        console.log(response.data);

        if (response.data.token) {
          const MODEL_URL = process.env.PUBLIC_URL + "/models";
          await Promise.all([
            faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
            faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
            faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
            faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
            faceapi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL),
          ]);

          setModelsLoaded(true);
          loadFaceData();
          // Cache loaded models to local storage if necessary
        } else {
          console.log("Model loading is not allowed.");
        }
      } catch (error) {
        console.error("Error loading models or fetching permission:", error);
      }
    };

    loadModels();
    // loadLocation()
    loadSetup();
    // loadFaceData();
  }, []);

  React.useEffect(() => {
    const face_list = JSON.parse(localStorage.getItem("faceList"));
    if (face_list) {
      setFaceList(face_list);
    }
  }, []);

  const loadFaceData = async () => {
    // call api to get face data
    setIsLoading(true);

    let face_data = await labledFaceFromData(state.faceData);
    setFaceList(face_data);

    setIsLoading(false);
    startVideo();
    setUserName("Memulai Face Recognition...");
  };

  const startVideo = () => {
    setCaptureVideo(true);
    navigator.mediaDevices
      .getUserMedia({ video: { width: 300 } })
      .then((stream) => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch((err) => {
        console.error("error:", err);
      });
  };

  const handleVideoOnPlay = () => {
    setUserName("Loading Data silahkan tunggu ...");
    myInterval = setInterval(async () => {
      if (canvasRef && canvasRef.current && dataSetup) {
        canvasRef.current.innerHTML = faceapi.createCanvasFromMedia(
          videoRef.current
        );
        const displaySize = {
          width: videoWidth,
          height: videoHeight,
        };

        try {
          faceapi.matchDimensions(canvasRef.current, displaySize);

          const detections = await faceapi
            .detectAllFaces(
              videoRef.current,
              new faceapi.TinyFaceDetectorOptions()
            )
            .withFaceLandmarks()
            .withFaceExpressions()
            .withFaceDescriptors();

          if (detections.length == 0) {
            setUserName("Sedang mendeteksi wajah ...");
          }
          if (detections.length > 0) {
            let landmark = detections[0].landmarks;
            const leftEye = landmark.getLeftEye();
            const rightEye = landmark.getRightEye();

            const distance = Math.sqrt(
              Math.pow(leftEye[0].x - rightEye[0].x, 2) +
                Math.pow(leftEye[0].y - rightEye[0].y, 2)
            );

            const threshold = dataSetup.jarak2;

            if (distance < threshold) {
              setUserName(
                "Posisikan wajah anda dekat di depan kamera dan di tengah"
              );
            } else {
              recognizeFace(detections);
              // if success recognize face
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    }, 1000);
  };

  const closeWebcam = () => {
    videoRef.current.pause();
    videoRef.current.srcObject.getTracks()[0].stop();
    setCaptureVideo(false);
    clearInterval();
  };
  const listLocation = dataLocation?.map((item) => {
    return { label: item.cust_name, cust_id: item.cust_id };
  });
  const ComboBox = () => {
    return (
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={listLocation}
        sx={{ width: "100%" }}
        // on select pegawai open webcam
        onChange={(event, newValue) => {
          if (newValue) {
            loadFaceData(newValue.cust_id);
          }
          setSelectedLocation(newValue);
        }}
        value={selectedLocation}
        disableClearable
        renderInput={(params) => <TextField {...params} label="Pilih Lokasi" />}
      />
    );
  };

  const labledFaceFromData = async (data) => {
    // console.log(data);
    const labeledFaceDescriptors = await Promise.all(
      data.map(async (item) => {
        const $name = item.nama + "-" + item.nik;
        const face_id = JSON.parse(item.face_id);
        const length = Object.keys(face_id).length;
        const face_data = new Float32Array(length);

        for (let i = 0; i < length; i++) {
          face_data[i] = face_id[i];
        }

        return new faceapi.LabeledFaceDescriptors($name, [face_data]);
      })
    );
    return labeledFaceDescriptors;
  };
  const recognizeFace = async (detections) => {
    const faceMatcher = new faceapi.FaceMatcher(faceList, 0.6);
    // console.log(faceMatcher);
    detections.forEach((detection) => {
      const bestMatch = faceMatcher.findBestMatch(detection.descriptor);
      // console.log(bestMatch);
      // setUserName(bestMatch._label);
      // if 60% match

      if (bestMatch._distance < dataSetup.acu2) {
        clearInterval(myInterval);
        setUserName(bestMatch._label);
        attendance(
          bestMatch._label.split("-")[1],
          bestMatch._label.split("-")[0],
          bestMatch
        );
      } else {
        setUserName("Wajah tidak dikenali / tidak terdaftar");
      }
    });
  };
  const dataURLtoBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };
  const attendance = async (nik, nama, best_match) => {
    const canvas = document.createElement("canvas");
    canvas.width = videoWidth;
    canvas.height = videoHeight;
    canvas
      .getContext("2d")
      .drawImage(videoRef.current, 0, 0, videoWidth, videoHeight);
    // create image full size
    //
    // date string for image name
    const date = new Date();
    // timestamp
    const time = date.getTime();
    const timestamp = Math.floor(time / 1000).toString();
    let name = nama;
    const image = canvas.toDataURL("image/png");
    const formData = new FormData();
    formData.append("nik", nik);
    formData.append("cust_id", state.selectedLocation.cust_id);
    formData.append(
      "foto",
      dataURLtoBlob(image),
      timestamp + "_" + nik + ".png"
    );
    formData.append("face_id", best_match);
    formData.append("shift", selectedShift.shift_id);

    try {
      const response = await axios.post(API_URL + "attend", formData);
      counter++;

      closeWebcam();

      MySwal.fire({
        icon: "success",
        title: "Berhasil Absen",
        // text: nameAbsen,
        // html with custom html big size green color
        html: `<h1>${name}</h1>`,

        showConfirmButton: false,
        timer: 3000,
      });
      // delete image

      // wait 3 second and startvideo
      handleOnSuccessAttendance();
    } catch (error) {
      setUserName("Absen Gagal");
      setTimeout(() => {
        window.location.reload();
      }, 1000);

      console.log(error);
    }
  };
  const handleOnSuccessAttendance = () => {
    // wait 3 second and startvideo
    console.log(counter);
    if (counter == 3) {
      console.log("reload");
      counter = 0;
      window.location.reload();
    }

    setTimeout(() => {
      // reaload page
      // window.location.reload();
      startVideo();
    }, 4000);
  };
  React.useEffect(() => {
    if (isWebcamOn) {
      // turn on webcam code
    } else {
      closeWebcam();
    }
  }, [isWebcamOn]);
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
        <Typography variant="h6" component="div">
          Loading
        </Typography>
      </Backdrop>
      <Box
        sx={{
          bgcolor: "background.paper",
          pt: 3,
          pb: 6,
        }}
      >
        <Container maxWidth="sm">
          {/* <ComboBox /> */}
          <Typography
            component="h6"
            variant="h6"
            align="center"
            color="text.info"
          >
            {selectedShift.label}
          </Typography>

          {captureVideo ? (
            modelsLoaded ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "10px",
                  }}
                >
                  <video
                    ref={videoRef}
                    height={videoHeight}
                    width={videoWidth}
                    onPlay={handleVideoOnPlay}
                    style={{ borderRadius: "10px" }}
                  />
                  <img
                    src={process.env.PUBLIC_URL + "/frame.png"}
                    style={{
                      position: "absolute",
                      width: { videoWidth },
                      height: "auto",
                      borderRadius: "10px",
                    }}
                  />
                  <canvas ref={canvasRef} style={{ position: "absolute" }} />
                </div>
                <div style={{ textAlign: "center" }}>
                  <h1
                    style={{
                      textAlign: "center",
                      color: "green",
                    }}
                  >
                    {userName === "Loading Data silahkan tunggu ..." ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      ""
                    )}{" "}
                    {userName}
                  </h1>
                </div>
              </div>
            ) : (
              <div>loading...</div>
            )
          ) : (
            <></>
          )}
        </Container>
      </Box>
    </div>
  );
}

export default FaceRecognition;
