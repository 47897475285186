import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Navbar from "../components/pages/Navbar";
import { HashRouter } from "react-router-dom";

import Home from "../components/pages/Home";
import RegisterFace from "../components/pages/RegisterFace";
import FaceRecognition from "../components/pages/FaceRecognition";
// import Login from "../components/pages/Login";
function AppRoutes() {
  return (
    // make navbar in router to make it appear in every page react js

    <Router basename="/">
      <Navbar />

      <Routes>
        {/* <Route path="/" exact element={<Login />} /> */}
        <Route path="/" exact element={<Home />} />
        <Route path="/register" element={<RegisterFace />} />
        <Route path="/absensi" element={<FaceRecognition />} />
      </Routes>
    </Router>
  );
}

export default AppRoutes;
