import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
//
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
export default function Navbar() {
  const home = window.location.href;
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar sx={{ flexWrap: "wrap" }}>
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            <Link
              to="#"
              // no style
              style={{ textDecoration: "none", color: "white" }}
            >
              Arei
            </Link>
          </Typography>

          <nav>
            <Button
              variant="button"
              color="text.primary"
              onClick={() => {
                window.location.reload();
              }}
              sx={{ my: 1, mx: 1.5 }}
            >
              Reload
            </Button>
          </nav>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
