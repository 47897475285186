import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import config from "../../config";
const theme = createTheme();

// const API_URL = "https://103.180.164.252:447/api/";
const API_URL = config.baseurl_api;
// const API_URL = "https://36.94.139.66:447/api/";
// const API_URL = "http://127.0.0.1:8000/api/";
export default function Home() {
  const [dataLocations, setDataLocations] = React.useState([]);
  const [dataShift, setDataShift] = React.useState([]); // [
  const [dataPegawai, setDataPegawai] = React.useState([]);
  const [selectedLocation, setSelectedLocation] = React.useState(null);
  const [selectedShift, setSelectedShift] = React.useState(null);
  const [faceData, setFaceData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [lokasi, setLokasi] = React.useState("Lokasi Belum Dipilih");
  const [shift, setShift] = React.useState("Shift Belum Dipilih");
  const dataLoc = JSON.parse(localStorage.getItem("dataLocations"));
  const dataShiftLoc = JSON.parse(localStorage.getItem("dataShift"));
  const selectedLoc = JSON.parse(localStorage.getItem("selectedLocation"));
  const selShift = JSON.parse(localStorage.getItem("selectedShift"));
  const faces = [];
  const pegawai = JSON.parse(localStorage.getItem("dataPegawai"));

  // if selected location is not null in localstorage

  const navigate = useNavigate();

  const getDataLocations = async () => {
    await axios
      .get(API_URL + "getCust")
      .then((res) => {
        console.log(res.data);
        setDataLocations(res.data.data);
        localStorage.setItem("dataLocations", JSON.stringify(res.data.data));
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  const getMasterShift = async () => {
    await axios
      .get(API_URL + "getMasterShift")
      .then((res) => {
        console.log(res.data);
        setDataShift(res.data.data);
        localStorage.setItem("dataShift", JSON.stringify(res.data.data));
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getFaceData = async (cust_id, status = "no") => {
    // setIsLoading(true);
    setIsLoading(true);

    await axios
      .get(API_URL + "getEmployeeAbsen/" + cust_id)
      .then((res) => {
        setFaceData(res.data.data);
        // localStorage.setItem("faceData", JSON.stringify(res.data.data));
        // getDataPegawai(cust_id);

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getDataPegawai = async (cust_id, status = "no") => {
    setIsLoading(true);

    await axios
      .get(API_URL + "getEmployee/" + cust_id)
      .then((res) => {
        setDataPegawai(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const listLocation =
    dataLocations?.map((item) => {
      return {
        label: item.cust_name,
        cust_id: item.cust_id,
      };
    }) || [];

  const listShift =
    dataShift?.map((item) => {
      return {
        label: item.nama,
        shift_id: item.shift_id,
      };
    }) || [];

  React.useEffect(() => {
    if (dataLoc) {
      setDataLocations(dataLoc);
    }
    if (dataShiftLoc) {
      setDataShift(dataShiftLoc);
    }
    if (selectedShift) {
      setSelectedShift(selectedShift);
    }

    if (selectedLoc) {
      setSelectedLocation(selectedLoc);
      setLokasi(selectedLoc.label);
      getFaceData(selectedLoc.cust_id);
      getDataPegawai(selectedLoc.cust_id);
    } else {
      getDataLocations();
    }
    if (selShift) {
      setSelectedShift(selShift);
      setShift(selShift.label);
    } else {
      getMasterShift();
    }
  }, []);

  const handleItemClick = () => {
    if (faceData.length !== 0) {
      navigate("/absensi", {
        state: {
          faceData,
          selectedLocation,
        },
      });
    }
  };
  const handleItemClickRegister = () => {
    if (dataPegawai.length !== 0) {
      navigate("/register", {
        state: {
          dataPegawai,
          selectedLocation,
        },
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
        <Typography variant="h6" component="div">
          Loading
        </Typography>
      </Backdrop>
      <CssBaseline />

      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: "background.paper",
            pt: 5,
            pb: 5,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h3"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Arei Attendance
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="text.secondary"
              paragraph
            >
              {lokasi ? lokasi : "Lokasi Belum Dipilih"}
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="text.secondary"
              paragraph
            >
              {shift ? shift : "Shift Belum Dipilih"}
            </Typography>
          </Container>
        </Box>
        <Container sx={{ py: 4 }} maxWidth="md">
          {/* End hero unit */}
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={listLocation}
            sx={{ width: "100%", marginBottom: "20px" }}
            // on select pegawai open webcam
            onChange={(event, newValue) => {
              // setIsLoading(true);

              if (newValue === null) {
                localStorage.removeItem("selectedLocation");
                localStorage.removeItem("faceData");
                localStorage.removeItem("dataPegawai");
                localStorage.removeItem("selectedShift");
                setSelectedLocation(null);
                setLokasi(null);
                setFaceData([]);
                setDataPegawai([]);
                // setIsLoading(false);
              } else {
                localStorage.removeItem("faceData");
                localStorage.removeItem("dataPegawai");
                localStorage.removeItem("selectedLocation");
                setSelectedLocation(newValue);
                setLokasi(newValue.label);
                setFaceData([]);
                setDataPegawai([]);

                localStorage.setItem(
                  "selectedLocation",
                  JSON.stringify(newValue)
                );
                getFaceData(newValue.cust_id, "change");
                getDataPegawai(newValue.cust_id, "change");
                // getDataPegawai(newValue.cust_id);
              }
            }}
            value={selectedLocation}
            renderInput={(params) => (
              <TextField {...params} label="Pilih Lokasi" />
            )}
          />
          {selectedLocation && (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={listShift}
              sx={{ width: "100%", marginBottom: "20px" }}
              // on select shift open webcam
              onChange={(event, newValue) => {
                if (newValue === null) {
                  localStorage.removeItem("selectedShift");
                  setSelectedShift(null);
                  setShift(null);
                } else {
                  localStorage.removeItem("selectedShift");
                  setSelectedShift(newValue);
                  setShift(newValue.label);
                  localStorage.setItem(
                    "selectedShift",
                    JSON.stringify(newValue)
                  );
                }
              }}
              value={selectedShift}
              renderInput={(params) => (
                <TextField {...params} label="Pilih Shift" />
              )}
            />
          )}

          {selectedLocation && selectedShift && (
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={4}>
                {faceData.length !== 0 && isLoading === false && (
                  <Card
                    onClick={() => {
                      handleItemClick();
                    }}
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography gutterBottom variant="h5" component="h2">
                        Absensi
                      </Typography>
                    </CardContent>
                  </Card>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                {dataPegawai.length !== 0 && isLoading === false && (
                  <Card
                    onClick={() => {
                      handleItemClickRegister();
                    }}
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography gutterBottom variant="h5" component="h2">
                        Registrasi Wajah
                      </Typography>
                    </CardContent>
                  </Card>
                )}
              </Grid>
            </Grid>
          )}
        </Container>
      </main>
    </ThemeProvider>
  );
}
